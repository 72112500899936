<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" v-if="!$vuetify.breakpoint.mobile"></appic-page-title-bar>
        <app-section-loader :status="loader"></app-section-loader>
        <v-container fluid grid-list-xl pb-0 pt-0 pl-5 pr-4>
            <v-layout row wrap>
                <app-card
                    :fullBlock="true"
                    colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <BuyerLocations></BuyerLocations>
                </app-card>
            </v-layout>
        </v-container>
    </div>
</template>

<script>
import LoadingComponent from "Components/Appic/LoadingComponent";

const BuyerLocations = () => ({
    component: import("Components/Appic/BuyerLocations"),
    loading: LoadingComponent
})

export default {
    name: "BuyerLocator",
    title: '',
    components: { BuyerLocations },
    data() {
        return {
            loader: false,
            pageKey: Math.floor(Math.random() * 100),
        }
    },
    mounted() {
        this.$title = this.$t('message.titles.buyerLocations')
    }
}
</script>

<style scoped>

</style>